import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { LocalStorageService } from "src/app/utils/localstorage.service";
import { environment } from "../../../../environments/environment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { LoginService } from "src/app/login/login.service";

@Injectable({ providedIn: "root" })
export class WorkspacesService {
  show_loading = new Subject<any>();
  workspaceClient = new Subject<any>();
  showSearch = new Subject<any>();
  showPinnedMessages = new Subject<any>();
  refreshPinnedMessages = new Subject<any>();
  checkTrackedItemsCount = new Subject<any>();
  refreshFilesManagerSubject = new Subject<boolean>();
  refreshSystemBotStatus = new Subject<any>();
  switchActiveTab = new Subject<{ tab: string; payload: any }>();
  removeTrackedItem = new Subject<any>();
  notificationsSelectedFileObservable = new BehaviorSubject<string>(null);

  changeMessagesFilter = new BehaviorSubject<any>("all");

  $hasQBOIntegration = new Subject<boolean>();
  $conversation = new BehaviorSubject<any>(null);
  $company = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  changeWorkspaceTab(tab: string, payload = {}) {
    this.switchActiveTab.next({ tab, payload });
  }

  notifyRefreshFilesManager() {
    this.refreshFilesManagerSubject.next(true);
  }

  refreshFilesManager(): Observable<boolean> {
    return this.refreshFilesManagerSubject.asObservable();
  }

  get_apps(company_id): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/company/${company_id}/apps`)
      .toPromise();
  }

  get_resources(company_id): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/company/${company_id}/resources`)
      .toPromise();
  }

  get_tracked_items(conversation_id): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/conversations/${conversation_id}/tracked`)
      .toPromise();
  }

  get_tracked_items_v2(conversation_id, filter, page = 0): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/v2/conversations/${conversation_id}/tracked?filter=${filter}&page=${page}`,
    );
  }

  get_qbo_tracked_items(conversation_id, filter): Promise<any> {
    return this.http
      .get(
        `${environment.apiUrl}/v2/conversations/${conversation_id}/qbo-tracked?filter=${filter}`,
      )
      .toPromise();
  }

  get_xero_tracked_items(
    conversation_id: number,
    filter: string,
  ): Promise<any> {
    return this.http
      .get(
        `${environment.apiUrl}/v2/conversations/${conversation_id}/xero-tracked?filter=${filter}`,
      )
      .toPromise();
  }

  get_tracked_items_tab_count(conversation_id, filter): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/v2/conversations/${conversation_id}/tracked.count?filter=${filter}`,
    );
  }

  get_options(conversation_id): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/conversations/${conversation_id}/settings`)
      .toPromise();
  }

  get_magic_search_flags(): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/company/magic-search`)
      .toPromise();
  }
}
